import { Button, DateBox, LoadPanel, TextArea } from 'devextreme-react'
import notify from 'devextreme/ui/notify';
import React, { useContext, useEffect, useState } from 'react'
import CopperService from '../../services/api/copper';
import RefService from '../../services/api/ref';
import _ from 'lodash';
import moment from 'moment';
import { UserContext } from '../../hooks/UserContext';
import { ConfigProvider, InputNumber, Form, Button as FormButton, Select, Tooltip } from 'antd';
import { FiMoon, FiSun } from 'react-icons/fi';
import { custom } from 'devextreme/ui/dialog';
import CopperHistory from './CopperHistory';
import Bundle from './Bundle';
import 'moment/locale/mn';

export const CopperRegistration = (props) => {
    moment.locale('mn');
    const { user } = useContext(UserContext);
    const [date, setDate] = useState(props.shift_date || moment().format('YYYY-MM-DD'))
    const [formatted, setFormatted] = useState(moment().format('YYYY-MM-DD dddd'))
    const [rejectedType, setRejectedType] = useState([])
    const [bathArray, setBathArray] = useState([]);
    const [bundleObj, setBundleObj] = useState({})
    const [loader, setLoader] = useState(true);
    const [sumObj, setSumObj] = useState({
        copper_count: 0,
        total_weight: 0,
        rejected_copper_count: 0,
        rejected_copper_weigth: 0,
        bundled_copper_count: 0,
        bundled_copper_weight: 0,
        balance_copper_count: 0,
        balance_copper_weight: 0
    })
    const [hangerData, setHangerData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [shift, setShift] = useState(null)
    const [shiftArr, setShiftArr] = useState([])
    const [form] = Form.useForm()
    const [initial, setInital] = useState({
        count: 0,
        weight: 0
    })

    useEffect(() => {
        rejectedTypes()
    }, [])

    useEffect(() => {
        getShiftId()
    }, [date])

    useEffect(() => {
        shift && getCopperRegByDate();
    }, [date, shift])

    useEffect(() => {
        setInital({
            count: bundleObj?.start_balance_copper_count + sumObj?.copper_count,
            weight: bundleObj?.start_balance_copper_weight + sumObj?.total_weight
        })
    }, [bundleObj, sumObj])

    const getShiftId = async () => {
        try {
            if (props.shift_id) {
                setShift(props.shift_id)
                setShiftArr([{ shift_id: props.shift_id }])
            } else {
                let { data } = await CopperService.getShiftId(date)
                setShiftArr(data)
                data.length === 1 ? setShift(data[0].shift_id) : setShift(1)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getCopperRegByDate = async () => {
        try {
            setLoader(true)
            let { data } = await CopperService.getCopperRegDetail(date, shift);
            data.length > 0 ? setBathArray(data[0].bathData) : setBathArray([])
            let { bathData, bundleData, ...filtered } = data[0];

            if (bundleData.length === 0) {
                bundleData = [{ bundle_no: data[0].current_bundle_no, bundled_copper_count: null, bundled_copper_weight: null }];
            }
            setBundleObj({ ...filtered, bundleData });

            if (data && data.length > 0) {
                //Хамгийн эхний бэлэн төлөвтэй ванны детайл, индекс
                const firstIndex = data[0].bathData.findIndex(item => item.bath_status_id === 1);
                if (firstIndex !== -1) {
                    const selectedBath = data[0].bathData[firstIndex];
                    if (selectedBath.hangerData && selectedBath.hangerData.length > 0) {
                        setHangerData(selectedBath);
                    }
                    setSelectedIndex(firstIndex);
                }
            }
            setSumObj({
                copper_count: _.sumBy(data[0].bathData, 'copper_count'),
                total_weight: _.sumBy(data[0].bathData, 'total_weight'),
                rejected_copper_count: _.sumBy(data[0].bathData, 'rejected_copper_count'),
                rejected_copper_weigth: _.sumBy(data[0].bathData, 'rejected_copper_weigth'),
                bundled_copper_count: _.sumBy(data[0].bundleData, 'bundled_copper_count'),
                bundled_copper_weight: _.sumBy(data[0].bundleData, 'bundled_copper_weight'),
                balance_copper_count: data[0].start_balance_copper_count + _.sumBy(data[0].bathData, 'copper_count') - _.sumBy(data[0].bundleData, 'bundled_copper_count'),
                balance_copper_weight: data[0].start_balance_copper_weight + _.sumBy(data[0].bathData, 'total_weight') - _.sumBy(data[0].bundleData, 'bundled_copper_weight')
            })
            return data[0].bundleData
        } catch (e) {
            console.error(e);
        } finally {
            setLoader(false)
        }
    }
    const handleEachBath = (bath_number) => {
        try {
            setLoader(true);
            let res = bathArray.filter(item => item.bath_number === bath_number);
            form.resetFields()
            res.length > 0 && setHangerData(res[0]);
            return setLoader(false)
        } catch (e) {
            setLoader(false)
            console.error(e);
        }
    }

    const rejectedTypes = async () => {
        try {
            let res = await RefService.getRejectedTypes();
            const changed = res.data.map(item => ({
                value: item.id,
                label: item.name
            }));
            setRejectedType(changed)
        } catch (e) {
            console.error(e);
        }
    }

    const updateHangerField = (bathNum, hangerNum, fieldName, value) => {
        setHangerData(prevHangerData => {
            if (prevHangerData.bath_number === bathNum) {
                return {
                    ...prevHangerData,
                    hangerData: prevHangerData.hangerData.map(hanger =>
                        hanger.hanger_number === hangerNum ? {
                            ...hanger,
                            [fieldName]: value,
                        } : hanger
                    )
                };
            }
            return prevHangerData;
        });
    };

    const updateBathField = (bathNum, fieldName, value) => {
        setHangerData(prevHangerData => {
            if (prevHangerData.bath_number === bathNum) {
                return {
                    ...prevHangerData,
                    [fieldName]: value,
                };
            }
            return prevHangerData;
        });
    };

    const updateHangerCopperCount = (bathNum, hangerNum, changeAmt) => {
        setHangerData(prevHangerData => {
            if (prevHangerData.bath_number === bathNum) {
                const updatedHangerData = prevHangerData.hangerData.map(hanger => {
                    if (hanger.hanger_number === hangerNum && hanger.hanger_status_id === 1) {
                        return {
                            ...hanger,
                            copper_count: changeAmt,
                        };
                    }
                    return hanger;
                });
                const totalCopperCount = updatedHangerData.reduce((sum, hanger) => sum + (hanger.hanger_status_id === 1 ? parseFloat(hanger.copper_count) : 0), 0);
                const recalculatedHangerData = updatedHangerData.map(hanger => {
                    const proportion = totalCopperCount ? (hanger.hanger_status_id === 1 ? parseFloat(hanger.copper_count) / totalCopperCount : 0) : 0;
                    return {
                        ...hanger,
                        copper_weigth: hanger.hanger_status_id === 1 ? (prevHangerData.total_weight * proportion).toFixed(2) : hanger.copper_weigth,
                    };
                });
                return {
                    ...prevHangerData,
                    hangerData: recalculatedHangerData,
                };
            }
            return prevHangerData;
        });
    }
    const updateBathTotalWeight = (bathNum, changeAmt) => {
        setHangerData(prevHangerData => {
            if (prevHangerData.bath_number === bathNum) {
                const totalCopperCount = prevHangerData.hangerData.reduce((sum, hanger) => hanger.hanger_status_id === 1 ? sum + parseFloat(hanger.copper_count) : sum, 0);
                const updatedHangerData = prevHangerData.hangerData.map(hanger => {
                    if (hanger.hanger_status_id === 1) {
                        const proportion = parseFloat(hanger.copper_count) / totalCopperCount;
                        return {
                            ...hanger,
                            copper_weigth: (changeAmt * proportion).toFixed(2),
                        };
                    }
                    return hanger;
                });

                return {
                    ...prevHangerData,
                    total_weight: changeAmt,
                    hangerData: updatedHangerData,
                };
            }
            return prevHangerData;
        });
    }

    const onSaveCopperMake = async (values) => {
        try {
            setLoader(true)
            await CopperService.mergeCopperRegistration(hangerData);
            await getCopperRegByDate();
            notify({ message: 'Амжилттай хадгалагдлаа', width: 'fit-content' }, "success", 2000);
            return setLoader(false)
        } catch (e) {
            notify('Хадгалах явцад алдаа гарлаа', "error", 2000);
            setLoader(false)
        }
    }

    const disableWeekends = (args) => {
        const dayOfWeek = args.date.getDay();
        const isWeekend = args.view === "month" && (dayOfWeek === 0 || dayOfWeek === 6);
        return isWeekend
    }

    const requestHandler = async () => {
        if (bundleObj.ref_copper_reg_status === 3) {
            setBundleObj({ ...bundleObj, ref_copper_reg_status: 1, status_name: 'Шинэ' })
        } else if (!bundleObj.bundleData[0].bundled_copper_count && !bundleObj.bundleData[0].bundled_copper_weight) {
            notify({ message: 'Боолтын мэдээллээ оруулна уу.', width: 'fit-content' }, "warning", 3000);
        } else {
            let myDialog = custom({
                title: "Баталгаажуулалт",
                messageHtml: `<i>Та <b>зэс хураалтын мэдээлэл</b> илгээхдээ итгэлтэй байна уу?</i>`,
                buttons: [{
                    text: "Тийм",
                    onClick: (e) => {
                        return { buttonText: e.component.option("text") }
                    },
                },
                {
                    text: "Үгүй",
                    onClick: (e) => {
                        return { buttonText: e.component.option("text") }
                    },
                }]
            })
            myDialog.show().then(async (dialogResult) => {
                if (dialogResult.buttonText === "Тийм") {
                    try {
                        await CopperService.changeRequestStatus({
                            id: bundleObj.id,
                            type: 2,
                            shift_id: bundleObj.shift_id,
                            shift_date: bundleObj.shift_date,
                        })
                        await getCopperRegByDate();
                        notify({ message: 'Амжилттай илгээгдлээ', width: 'fit-content' }, "success", 3000);
                    } catch (error) {
                        console.log('error', error)

                    }
                }
            })
        }
    }

    const checkRules = (trueFalser, max, hangerStatusId) => {
        if (hangerStatusId !== 1) {
            return [];
        }
        return [
            { required: trueFalser, message: 'Заавал оруулах' },
            { type: 'number', min: 0, message: 'Утга шалгана уу' },
            { type: 'number', max: max, message: 'Хязгаараас их' },
        ];
    };

    return (
        <div>
            <ConfigProvider
                theme={{
                    token: {
                        borderRadius: 15,
                    }
                }}
            >
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#password' }}
                    visible={loader}
                    showPane={false}
                    message="Түр хүлээнэ үү..."
                />
                <div className='d-flex justify-space-between m-2' style={{ display: props.shift_id ? 'none' : 'flex' }}>
                    <div className='d-flex g-2 customizeDateBox'>
                        <DateBox
                            width={200}
                            height={40}
                            value={date}
                            type='date'
                            stylingMode='outlined'
                            displayFormat={formatted}
                            onValueChange={(e) => {
                                setFormatted(moment(e).format('YYYY-MM-DD dddd'));
                                setDate(e);
                            }}
                            style={{ borderRadius: 20, border: 'none', paddingLeft: 15 }}
                            pickerType='calendar'
                            disabledDates={disableWeekends}
                        />
                        {shift && <div style={{ alignItems: 'center', justifyContent: 'space-between', height: 40 }}>
                            <div className='eelj' onClick={() => shiftArr.length > 1 && setShift(shift === 1 ? 2 : 1)} style={{ flexWrap: 'wrap', height: 40, width: 200 }}>
                                <div style={{ borderRight: '1px solid #DEDEDE', width: '83%', display: 'flex', alignItems: 'center' }}>
                                    <p style={{ padding: '7px 0 8px 15px' }}><b>{shift}-р ээлж </b>{shift === 1 ? '08:00-16:00' : '16:00-00:00'}</p>
                                </div>
                                <div style={{ width: '17%', textAlign: 'center', margin: 'auto', verticalAlign: 'middle', paddingTop: 3 }}>
                                    {shift === 1 ? <FiSun size={17} color='orange' /> : <FiMoon size={17} color='#003696' />}
                                </div>
                            </div>
                        </div>}
                    </div>
                    <Button
                        text={[1, 3].includes(bundleObj?.ref_copper_reg_status) ? 'Хянуулах' : bundleObj.status_name}
                        visible={!_.isEmpty(bundleObj)}
                        disabled={[2, 4].includes(bundleObj.ref_copper_reg_status) || (shiftArr.length === 0)}
                        type='normal'
                        style={{
                            borderRadius: 20, padding: '8px 20px', paddingBlock: 0, fontWeight: 'bold',
                            color: `${bundleObj?.ref_copper_reg_status === 4 ? '#40b6af' : '#000'}`,
                            backgroundColor: `${bundleObj?.ref_copper_reg_status === 4 ? '#e5fef7' : '#fff'}`,
                            border: `1px solid ${bundleObj?.ref_copper_reg_status === 4 ? '#40b6af' : '#ced4df'}`
                        }}
                        onClick={requestHandler}
                    />
                </div>
                {bathArray.length > 0 && shiftArr.length > 0 ?
                    <div>
                        <div className={'card'} style={{ padding: 10, border: 'none' }}>
                            <div className='m-2' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <b>1.ЗЭС ХУРААЛТ ХИЙХ</b>
                                <div>
                                    <span className='text-card mr-2' style={{ border: '1px solid #dedede', color: '#b8b4b4' }}>LOT {bundleObj.lot_no || bundleObj.current_lot_no}</span>
                                    <span className='text-card' style={{ color: '#f29f67', background: '#fcf6f0' }}>ванн №{hangerData.bath_number}</span>
                                </div>
                            </div>
                            <div className=''>
                                <div className='row d-flex align-item-center m-3'>
                                    <div className='card d-flex justify-space-between p-2 align-item-center flex-col' style={{ width: 100, height: 150 }}>
                                        <p style={{ textAlign: 'center' }}>Өмнөх үлдэгдэл</p>
                                        <p className='balance-text'>{bundleObj.start_balance_copper_count ? `${bundleObj.start_balance_copper_count} ш` : '-'}</p>
                                        <p className='balance-text'>{bundleObj.start_balance_copper_weight ? `${bundleObj.start_balance_copper_weight} кг` : '-'} </p>
                                    </div>
                                    {bathArray.length > 0 &&
                                        <div className='d-flex align-item-center' style={{}}>
                                            {bathArray.map((e, i) => {
                                                const colorClass = e.bath_status_id === 1 ? '#f29f67' : e.bath_status_id === 2 ? '#ced4df' : '#f95f53';
                                                return (
                                                    <Tooltip placement='bottom' defaultOpen={false} title={e.bath_status_id !== 1 && <span>Ванны дугаар: {e.bath_number}<br /> Ванны төлөв: {e.status_name}<br /> Шинэчлэгдсэн: {e.updatedat ? moment(e.updatedat).format('YYYY-MM-DD') : '-'}</span>}>
                                                        <div className='bath-polygon' aria-disabled={e.bath_status_id !== 1}
                                                            style={{ borderColor: colorClass, backgroundColor: selectedIndex === i ? '#fef5ef' : 'white' }} key={i} id={`bath_${e.bath_number}`}
                                                            onClick={(e1) => {
                                                                setSelectedIndex(i);
                                                                selectedIndex !== i && handleEachBath(e.bath_number);
                                                            }}>
                                                            {
                                                                e.bath_status_id !== 1 ? <div style={{ color: colorClass, fontWeight: 'bold' }}>{e.bath_number}</div>
                                                                    :
                                                                    <><div style={{ color: colorClass, fontWeight: 'bold' }}>{e.bath_number}</div>
                                                                        <hr style={{ backgroundColor: colorClass }} />
                                                                        <div className='bath-data' style={{ color: '#0c0c0c' }}>{e.katod_count || '-'}</div>
                                                                        <hr style={{ backgroundColor: colorClass }} />
                                                                        <div className='bath-copper' style={{ color: '#0c0c0c' }}>{e.copper_count || '-'}</div>
                                                                        <hr style={{ backgroundColor: colorClass }} />
                                                                        <div className='bath-weight' style={{ color: '#0c0c0c' }}>{e.total_weight ? `${e.total_weight} кг` : '-'}</div>
                                                                    </>
                                                            }
                                                        </div>
                                                    </Tooltip>
                                                )
                                            }
                                            )}
                                        </div>}
                                    <div className='card d-flex justify-space-between p-2 align-item-center flex-col' style={{ width: 100, height: 150 }}>
                                        <p style={{ textAlign: 'center' }}>Хоногийн хураалт</p>
                                        <p className='balance-text'>{sumObj.copper_count ? `${sumObj.copper_count} ш` : '-'}</p>
                                        <p className='balance-text'>{sumObj.total_weight ? `${sumObj.total_weight} кг` : '-'}</p>
                                    </div>
                                    <div className='card d-flex justify-space-between p-2 align-item-center flex-col' style={{ width: 100, height: 150 }}>
                                        <p style={{ textAlign: 'center' }}>Нийт гологдол</p>
                                        <p className='balance-text'>{sumObj.rejected_copper_count ? `${sumObj.rejected_copper_count} ш` : '-'}</p>
                                        <p className='balance-text'>{sumObj.rejected_copper_weigth ? `${sumObj.rejected_copper_weigth} кг` : '-'} </p>
                                    </div>
                                </div>
                                <div className='row m-3' style={{ marginTop: 30 }}>
                                    {hangerData &&
                                        <Form
                                            onFinish={onSaveCopperMake}
                                            form={form}
                                            className='d-flex'
                                            style={{ width: '100%' }}
                                        >
                                            <div className='row justify-space-between align-item-end custom-input-numbers'>
                                                <div className='d-flex g-3'>
                                                    <div className='d-flex flex-col' style={{ margin: '50px 0', gap: 32 }}>
                                                        <div style={{ fontWeight: '500' }}>Хураасан катодын тоо: <span style={{ color: 'red' }}>*</span></div>
                                                        <div style={{ fontWeight: '500', }}>Хураасан хавтангийн тоо: <span style={{ color: 'red' }}>*</span></div>
                                                        <div style={{ fontWeight: '500', }}>Хураасан хавтангийн жин: <span style={{ color: 'red' }}>*</span></div>
                                                        <div style={{ fontWeight: '500', }}>Тайлбар:</div>
                                                    </div>
                                                    <div >
                                                        <div className='d-flex g-3'>
                                                            {hangerData && hangerData.hangerData !== undefined && hangerData.hangerData.map((data, i) => {
                                                                return (

                                                                    <div className='d-flex flex-col'>
                                                                        <Tooltip placement='bottom' defaultOpen={false} title={data.hanger_status_id !== 1 && <span>Өлгүүрийн төлөв: {data.hanger_status}<br /> Шинэчлэгдсэн: {data.updatedat ? moment(data.updatedat).format('YYYY-MM-DD') : '-'}</span>}>
                                                                            <div className={'hanger-card'} style={{ marginBottom: 16, color: data.hanger_status_id === 2 ? '#ced4df' : data.hanger_status_id === 3 ? '#f95f53' : '#000', borderColor: data.hanger_status_id === 3 ? '#f95f53' : '#ced4df' }}>
                                                                                {'Өлгүүр ' + data.hanger_number}
                                                                            </div>
                                                                        </Tooltip>
                                                                        <Form.Item name={`katod_count-${i}-${data.bath_number}`} rules={checkRules(!data.katod_count, data.bath_hang_katod_count, data.hanger_status_id)}>
                                                                            <InputNumber
                                                                                controls={false}
                                                                                value={data.katod_count}
                                                                                defaultValue={hangerData.bath_status_id === 1 && data.hanger_status_id === 1 && data.katod_count}
                                                                                type='number'
                                                                                className={'number-card'}
                                                                                onChange={(e) => e !== null && updateHangerField(data.bath_number, data.hanger_number, 'katod_count', e)}
                                                                                addonAfter={data.bath_hang_katod_count}
                                                                                readOnly={[2, 4].includes(bundleObj.ref_copper_reg_status) || data.hanger_status_id !== 1 || hangerData.bath_status_id !== 1}
                                                                            />
                                                                        </Form.Item>
                                                                        <Form.Item name={`copper_count-${i}-${data.bath_number}`} rules={checkRules(!data.copper_count, data.bath_hang_katod_count * 2, data.hanger_status_id)}>
                                                                            <InputNumber
                                                                                controls={false}
                                                                                value={data.copper_count}
                                                                                defaultValue={hangerData.bath_status_id === 1 && data.hanger_status_id === 1 && data.copper_count}
                                                                                type='number'
                                                                                className={'number-card'}
                                                                                onChange={(e) => e !== null && updateHangerCopperCount(data.bath_number, data.hanger_number, e)}
                                                                                addonAfter={data.bath_hang_katod_count * 2}
                                                                                readOnly={[2, 4].includes(bundleObj.ref_copper_reg_status) || data.hanger_status_id !== 1 || hangerData.bath_status_id !== 1}
                                                                            />
                                                                        </Form.Item>
                                                                        <InputNumber
                                                                            controls={false}
                                                                            value={hangerData.total_weight && data.copper_weigth}
                                                                            type='number'
                                                                            className={'number-card'}
                                                                            addonAfter='кг'
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        <TextArea
                                                            autoResizeEnabled={true}
                                                            height={100}
                                                            maxHeight={200}
                                                            maxLength={500}
                                                            width={392}
                                                            value={hangerData.description}
                                                            stylingMode={'outlined'}
                                                            valueChangeEvent='keyup'
                                                            style={{ padding: 10, borderRadius: 20, marginTop: 14 }}
                                                            placeholder='Тайлбар оруулах'
                                                            onValueChanged={(e) => e.event !== undefined && updateBathField(hangerData.bath_number, 'description', e.value)}
                                                            readOnly={[2, 4].includes(bundleObj.ref_copper_reg_status) || hangerData.bath_status_id !== 1}
                                                        />
                                                    </div>
                                                    <div className='d-flex flex-col' style={{ gap: 14.5 }}>
                                                        <div className={'hanger-card'} style={{ color: '#f29f67', borderColor: '#f29f67' }}>
                                                            Ванн нийт
                                                        </div>
                                                        <InputNumber
                                                            controls={false}
                                                            value={(hangerData.bath_status_id === 1 && _.sumBy(_.filter(hangerData.hangerData, item => item.hanger_status_id === 1), 'katod_count')) || null}
                                                            type='number'
                                                            className={'number-card'}
                                                            min={0}
                                                            readOnly={true}
                                                            addonAfter={_.sumBy(_.filter(hangerData.hangerData, item => item.hanger_status_id === 1), 'bath_hang_katod_count') || _.sumBy(hangerData.hangerData, 'bath_hang_katod_count')}
                                                        />
                                                        <InputNumber
                                                            controls={false}
                                                            addonAfter={_.sumBy(_.filter(hangerData.hangerData, item => item.hanger_status_id === 1), 'bath_hang_katod_count') * 2 || _.sumBy(hangerData.hangerData, 'bath_hang_katod_count') * 2}
                                                            value={(hangerData.bath_status_id === 1 && _.sumBy(_.filter(hangerData.hangerData, item => item.hanger_status_id === 1), 'copper_count')) || null}
                                                            type='number'
                                                            format="#"
                                                            className={'number-card'}
                                                            min={0}
                                                            readOnly={true}
                                                        />
                                                        <Form.Item name={`total_weight-${hangerData.bath_number}`} rules={hangerData.bath_status_id !== 1 ? [] : [{ required: !hangerData.total_weight, message: 'Заавал оруулах' }, { type: 'number', min: 0, message: 'Утга шалгана уу' }]}>
                                                            <InputNumber
                                                                controls={false}
                                                                value={hangerData.total_weight}
                                                                defaultValue={hangerData.total_weight}
                                                                readOnly={[2, 4].includes(bundleObj.ref_copper_reg_status) || hangerData.bath_status_id !== 1}
                                                                type='number'
                                                                addonAfter='кг'
                                                                className={'number-card'}
                                                                min={0}
                                                                onChange={(e) => { e !== null && updateBathTotalWeight(hangerData.bath_number, e); }}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <div className='d-flex flex-col' >
                                                        <div className={'hanger-card'} style={{ marginBottom: 16 }}>
                                                            Гологдол
                                                        </div>
                                                        <Form.Item name={`copper_rejected_type_id-${hangerData.bath_number}`} rules={[{ required: hangerData.copper_rejected_type_id ? false : (hangerData.rejected_copper_count || hangerData.rejected_copper_weigth) ? true : false, message: 'Заавал сонгох' }]}>
                                                            <Select
                                                                allowClear
                                                                onChange={(e) => updateBathField(hangerData.bath_number, 'copper_rejected_type_id', e === undefined ? null : e)}
                                                                value={hangerData.copper_rejected_type_id}
                                                                defaultValue={hangerData.copper_rejected_type_id}
                                                                options={rejectedType}
                                                                style={{ width: 120 }}
                                                                popupMatchSelectWidth={300}
                                                                placeholder='сонгох...'
                                                                aria-disabled={hangerData.bath_status_id !== 1}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name={`rejected_copper_count-${hangerData.bath_number}`} rules={[{ required: hangerData.rejected_copper_count ? false : hangerData.copper_rejected_type_id || hangerData.rejected_copper_weigth ? true : false, message: 'Заавал оруулах' }, { type: 'number', min: 0, message: 'Утга шалгана уу' }, { type: 'number', max: _.sumBy(hangerData.hangerData, 'bath_hang_katod_count') * 2, message: 'Хязгаараас их' }]}>
                                                            <InputNumber
                                                                controls={false}
                                                                value={hangerData.rejected_copper_count}
                                                                defaultValue={hangerData.rejected_copper_count}
                                                                type='number'
                                                                style={{ minWidth: 120 }}
                                                                className={'number-card'}
                                                                readOnly={hangerData.bath_status_id !== 1}
                                                                onChange={(e) => updateBathField(hangerData.bath_number, 'rejected_copper_count', e)}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name={`rejected_copper_weigth-${hangerData.bath_number}`} rules={[{ required: hangerData.rejected_copper_weigth ? false : hangerData.rejected_copper_count || hangerData.copper_rejected_type_id ? true : false, message: 'Заавал оруулах' }, { type: 'number', min: 0, message: 'Утга шалгана уу' }, { type: 'number', max: hangerData.total_weight, message: 'Утга шалгана уу' }]}>
                                                            <InputNumber
                                                                width={120}
                                                                controls={false}
                                                                value={hangerData.rejected_copper_weigth}
                                                                defaultValue={hangerData.rejected_copper_weigth}
                                                                type='number'
                                                                className={'number-card'}
                                                                addonAfter='кг'
                                                                readOnly={hangerData.bath_status_id !== 1}
                                                                onChange={(e) => updateBathField(hangerData.bath_number, 'rejected_copper_weigth', e)}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div>
                                                    <FormButton
                                                        htmlType="submit"
                                                        style={{ border: '1px solid #ced4df', fontWeight: 600, marginRight: 25 }}
                                                        disabled={[2, 4].includes(bundleObj.ref_copper_reg_status) || hangerData.bath_status_id !== 1}
                                                        type="text"
                                                    >
                                                        Хадгалах
                                                    </FormButton>
                                                </div>
                                            </div>
                                        </Form>
                                    }
                                </div>
                            </div>
                        </div>
                        <Bundle getCopperRegByDate={getCopperRegByDate} bundleObj={bundleObj} sumObj={sumObj} setBundleObj={setBundleObj} setSumObj={setSumObj} setLoader={setLoader} initial={initial} type={1} />
                        <div className='card' style={{ gap: 5, padding: 5, border: 'none', marginTop: 10 }}>
                            <CopperHistory shift_date={date} shift_id={shift} />
                        </div>
                    </div>
                    :
                    !loader && <div className='card m-2 p-3' style={{ border: 'none' }}>Та өнөөдөр ээлжгүй байна.</div>
                }
            </ConfigProvider>
        </div>
    )
}
