import { InputNumber, Button as FormButton, Upload, Image } from 'antd'
import { Button } from 'devextreme-react/button'
import React, { useEffect, useRef, useState } from 'react'
import CopperService from '../../services/api/copper'
import FileServices from '../../services/api/file'
import Compressor from 'compressorjs'
import notify from 'devextreme/ui/notify'
import _ from 'lodash'
import { custom } from 'devextreme/ui/dialog'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { PiEye } from 'react-icons/pi'
import { ImAttachment } from 'react-icons/im'
import { urlServer } from '../../shared/lib/request'
import { Popup } from 'devextreme-react'
import styled from 'styled-components'
import EmptyData from '../../components/EmptyData'
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";


const BunldeDiv = styled.div`
.ant-input-textarea::-webkit-scrollbar {
        width: 10px !important;
    }
    .ant-input-textarea::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 15px !important;
    }
  
    .ant-input-textarea::-webkit-scrollbar-thumb {
        background: #dedede;
        border-radius: 10px;
    }
    .ant-input-textarea::-webkit-scrollbar-thumb:hover {
        background: #333;
    }`

const Bundle = ({ getCopperRegByDate, bundleObj, sumObj, setBundleObj, setSumObj, setLoader, initial, type }) => {
    const fileInputRef = useRef(null)
    const [files, setFiles] = useState([])
    const [click, setClick] = useState(false)
    const [popup, setPopup] = useState(false)
    const [removedFileNames, setRemovedFileNames] = useState([])
    const [fileRoute, setFileRoute] = useState("")
    const [bundleData, setBundleData] = useState({
        no: 0,
        count: 0,
        weight: 0
    })

    const onSaveBundle = async () => {
        try {
            let myDialog = custom({
                title: "Баталгаажуулалт",
                messageHtml: `<i>Та <b>боолтын мэдээлэл</b> хадгалахдаа итгэлтэй байна уу?</i>`,
                buttons: [
                    {
                        text: "Тийм",
                        onClick: (e) => {
                            return { buttonText: e.component.option("text") }
                        },
                    },
                    {
                        text: "Үгүй",
                        onClick: (e) => {
                            return { buttonText: e.component.option("text") }
                        },
                    }
                ]
            });

            myDialog.show().then(async (dialogResult) => {
                if (dialogResult.buttonText === "Тийм") {
                    try {
                        setLoader(true);
                        if (removedFileNames.length > 0) {
                            const uploadPromises = removedFileNames.map(async (e) => {
                                await FileServices.deleteFile(e)
                                return Promise.resolve()
                            })
                            await Promise.all(uploadPromises)
                            setRemovedFileNames([])
                        }
                        await CopperService.mergeBundleRegistration(bundleObj);
                        const res = await getCopperRegByDate();
                        const uploadPromises = res.map(async (e) => {
                            if (files[e.bundle_no]) {
                                return fileSubmit(files[e.bundle_no], e.id, e.bundle_no, 30000000)
                            }
                            return Promise.resolve()
                        })
                        await Promise.all(uploadPromises)
                        setFiles([])
                        notify({ message: 'Амжилттай хадгалагдлаа', width: 'fit-content' }, "success", 2000);
                        return setLoader(false);
                    } catch (e) {
                        notify('Хадгалах явцад алдаа гарлаа', "error", 2000);
                        setLoader(false);
                    }
                }
            });
        } catch (errorInfo) {
            console.log('Validation Failed:', errorInfo);
        }
    };
    const fileSubmit = async (fileData, id, bundleNo, size) => {
        try {
            if (fileData.size >= size) {
                notify(
                    `Оруулсан файлын хэмжээ  ${size / 1000000}-MB-аас их байна.`,
                    "warning",
                    3000
                )
                return 0
            }
            let data = new FormData()
            if (fileData.size / 1024 > 500 && fileData.type.includes("image")) {
                await new Promise((resolve) => {
                    new Compressor(fileData, {
                        quality: 0.8,
                        type: "image/jpeg",
                        convertTypes: ["image/png", "image/webp"],
                        convertSize: 500000,
                        success: async (compressedResult) => {
                            const arrayBuffer = await compressedResult.arrayBuffer()
                            let compressedFile = new File([arrayBuffer], fileData.name, {
                                type: fileData.type,
                            })
                            data.append("file", compressedFile)
                            resolve()
                        },
                    })
                })
            } else {
                data.append("file", fileData)
            }

            const res = await FileServices.fileUpload(id, 1, data)
            if (res.code === 200) {
                setFiles((prev) => {
                    const newFiles = { ...prev };
                    delete newFiles[bundleNo];
                    return newFiles;
                });
                return res.data
            }
        } catch (error) {
            console.log(error)
            return notify("Хадгалах үйлдэл амжилтгүй боллоо (Зураг)", "warning", 3000)
        }
    }
    const handleBundleColumn = () => {
        if (sumObj.balance_copper_count > 0 && sumObj.balance_copper_weight > 0) {
            let lastEl = bundleObj.bundleData[bundleObj.bundleData.length - 1];
            if (lastEl.bundled_copper_count && lastEl.bundled_copper_weight) {
                if ((lastEl.lot_no && lastEl.lot_no === bundleObj.current_lot_no) || !lastEl.lot_no) {
                    setBundleObj({ ...bundleObj, bundleData: [...bundleObj.bundleData, { bundle_no: lastEl.bundle_no + 1 }] })
                } else if (lastEl.lot_no !== bundleObj.current_lot_no) {
                    setBundleObj({ ...bundleObj, bundleData: [...bundleObj.bundleData, { bundle_no: 1 }] })
                }
                setSumObj({
                    ...sumObj,
                    balance_copper_count: (bundleObj.start_balance_copper_count + sumObj.copper_count) - _.sumBy(bundleObj.bundleData, 'bundled_copper_count'),
                    balance_copper_weight: (bundleObj.start_balance_copper_weight + sumObj.total_weight) - _.sumBy(bundleObj.bundleData, 'bundled_copper_weight')
                })
            }
            else {
                notify({ message: 'Боолтын мэдээллээ гүйцэт оруулна уу.', width: 'fit-content' }, "warning", 3000);
            }
        } else {
            notify({ message: 'Үлдэгдэл хүрэлцэхгүй байна.', width: 'fit-content' }, "warning", 3000);
        }
    }
    const removeBundle = (bundle_no) => {
        let updatedBundleData = bundleObj.bundleData.filter((bundle) => bundle.bundle_no !== bundle_no);
        let removedBundle = bundleObj.bundleData.find((bundle) => bundle.bundle_no === bundle_no)
        if (removedBundle.file_name) {
            setRemovedFileNames((prevIds) => [...prevIds, removedBundle.file_name]);
        }
        setFiles((prev) => {
            const newFiles = { ...prev };
            delete newFiles[bundle_no];
            return newFiles;
        });
        updatedBundleData = updatedBundleData.map((bundle) => {
            if (bundle.bundle_no > bundle_no) {
                return {
                    ...bundle,
                    bundle_no: bundle.bundle_no - 1
                };
            }
            return bundle;
        });
        setBundleObj({ ...bundleObj, bundleData: updatedBundleData });
        setSumObj({
            ...sumObj,
            balance_copper_count: (bundleObj.start_balance_copper_count + sumObj.copper_count) - _.sumBy(updatedBundleData, 'bundled_copper_count'),
            balance_copper_weight: (bundleObj.start_balance_copper_weight + sumObj.total_weight) - _.sumBy(updatedBundleData, 'bundled_copper_weight')
        })
    };
    const updateBundleCopNum = (no, value) => {
        if (sumObj.balance_copper_count > value) {
            setBundleObj({
                ...bundleObj,
                bundleData: bundleObj.bundleData.map(it =>
                    it.bundle_no === no ? {
                        ...it, bundled_copper_count: value
                    } : it
                )
            });
        } else {
            notify({ message: 'Үлдэгдэл хүрэлцэхгүй байна.', width: 'fit-content' }, "warning", 3000);
        }
    }
    const updateBundleCopWeight = (no, value) => {
        if (sumObj.balance_copper_weight > value) {
            setBundleObj({
                ...bundleObj,
                bundleData: bundleObj.bundleData.map(it =>
                    it.bundle_no === no ? {
                        ...it, bundled_copper_weight: value
                    } : it
                )
            })
        } else {
            notify({ message: 'Үлдэгдэл хүрэлцэхгүй байна.', width: 'fit-content' }, "warning", 3000);
        }
    }
    const handleClickOutside = () => {
        if (click) {
            setClick(false); // Reset the state
        }
    };

    const triggerFileInput = () => {
        setClick(true)
        fileInputRef.current.click();
    };
    const showPhoto = (file_name, bundleNo) => {
        setPopup(true)
        let url = `${urlServer}/api/uploads/documents/${file_name}`
        setFileRoute(url)
    }
    const handleFileChange = async (event, id, bundle_no, file_name) => {
        const file = event.target.files[0];
        setClick(false)
        if (file) {
            await FileServices.deleteFile(file_name)
            await fileSubmit(file, id, bundle_no, 30000000)
            await getCopperRegByDate()
        }
    };
    const beforeUpload = (file, bundleNo) => {
        setFiles((prev) => ({ ...prev, [bundleNo]: file }));
        return false;
    };

    const handleRemove = (bundleNo) => {
        setFiles((prev) => {
            const newFiles = { ...prev };
            delete newFiles[bundleNo];
            return newFiles;
        });
    };
    let arr = ['Боодлын дугаар:', 'Хавтангийн тоо:','Хавтангийн жин:']
    return (
        <BunldeDiv className={'card'} style={{ padding: 10, border: 'none', marginTop: 10 }}>
            <div className='d-flex justify-space-between m-2'>
                {type === 1 ? <b>2. БООЛТ ХИЙХ</b> : <h4 style={{ fontSize: 13, fontWeight: 700, letterSpacing: 0.2 }}>3. БООЛТЫН ДЭЛГЭРЭНГҮЙ</h4>}
                {type === 1 && !bundleObj.start_balance_copper_count && !bundleObj.start_balance_copper_weight && bundleObj.ref_copper_reg_status === 1 &&
                    <p style={{ color: '#f66160' }}>Өмнөх ээлжийн зэсийн бүртгэлийг <strong>БАТЛААГҮЙ</strong> байгаа тул боолт хийх боломжгүй!</p>}
            </div>
            {type === 2 && bundleObj?.bundleData?.length === 0 ?
                <EmptyData/>
                :
                <div className='row' style={{ display: 'flex', gap: 20, paddingTop: 10, padding: type === 2 ? 20 : 0 }}>
                    {type === 1 &&
                        <div className='card d-flex justify-space-between p-2 align-item-center flex-col' style={{ width: 100, height: 150 }}>
                            <p style={{ textAlign: 'center' }}>Нийт үлдэгдэл</p>
                            <p className='balance-text'>{initial.count ? `${initial.count} ш` : '-'}</p>
                            <p className='balance-text'>{initial.weight ? `${initial.weight} кг` : '-'}</p>
                        </div>
                    }
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', width: 'calc(100% - 130px)' }}>
                        <div className='col-12' style={{ display: 'flex', paddingTop: 5 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 5, height: type === 1 ? '14rem' : 'auto', width: '170px', fontWeight: 500 }}>
                                <div style={{ width: '140px', height: 32, marginTop: 5 }}>Боодлын дугаар:</div>
                                <div style={{ width: '140px', height: 32 }}>Хавтангийн тоо:<span style={{ color: 'red' }}>*</span></div>
                                <div style={{ width: '140px', height: 32 }}>Хавтангийн жин, кг:<span style={{ color: 'red' }}>*</span></div>
                                <div style={{ width: '140px', height: 32 }}>{type === 1 ? 'Зураг хавсаргах:' : 'Хасваргасан зураг'}<span style={{ color: 'red' }}>*</span></div>
                                <Button text='+'
                                    visible={type === 1}
                                    style={{ width: 90, borderRadius: 20 }}
                                    onClick={handleBundleColumn}
                                    disabled={[2, 4].includes(bundleObj.ref_copper_reg_status) || (!bundleObj.start_balance_copper_count && !bundleObj.start_balance_copper_weight)}
                                />
                            </div>
                            <div className='bundle-container' style={{ display: 'flex', gap: 10, height: type === 1 ? '14rem' : 'auto', overflowX: 'auto' }} onClick={handleClickOutside}>
                                {bundleObj && bundleObj.bundleData !== undefined &&
                                    bundleObj.bundleData.map((e, i) => (
                                        <div key={i} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, fontWeight: 500, width: 130, flex: 1, flexGrow: 'initial' }}>
                                            <InputNumber
                                                controls={false}
                                                className='centered-input-number'
                                                style={{ width: 120, borderRadius: 15}}
                                                value={`№${e.lot_no ? e.lot_no + '/' + e.bundle_no : bundleObj.current_lot_no + '/' + e.bundle_no}`}
                                                readOnly />
                                            <InputNumber
                                                controls={false}
                                                className='centered-input-number'
                                                style={{ width: 120, borderRadius: 15}}
                                                value={e.bundled_copper_count}
                                                readOnly={[2, 4].includes(bundleObj.ref_copper_reg_status)}
                                                defaultValue={e.bundled_copper_count}
                                                type='number'
                                                min={0}
                                                inputMode='numeric'
                                                onChange={(value) => value !== null && updateBundleCopNum(e.bundle_no, value)}
                                            />
                                            <InputNumber
                                                controls={false}
                                                className='centered-input-number'
                                                style={{ width: 120, borderRadius: 15}}
                                                value={e.bundled_copper_weight}
                                                readOnly={[2, 4].includes(bundleObj.ref_copper_reg_status)}
                                                type='number'
                                                min={0}
                                                onChange={(value) => value !== null && updateBundleCopWeight(e.bundle_no, value)}
                                            />
                                            {e.id && e.file_id !== null ?
                                                (type === 1 ? <FormButton.Group style={{ width: '100%' }}>
                                                    <FormButton style={{ width: '70%', borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }} onClick={() => {
                                                        showPhoto(e.file_name, e.bundle_no)
                                                        setBundleData({no: e.bundle_no, count: e.bundled_copper_count, weight: e.bundled_copper_weight})
                                                    }}><PiEye size={22} /></FormButton>
                                                    <input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        style={{ display: 'none' }}
                                                        onChange={(event) => handleFileChange(event, e.id, e.bundle_no, e.file_name)}
                                                    />
                                                    <FormButton style={{ display: 'block',  width: '30%', padding: 5, borderBottomRightRadius: 15, borderTopRightRadius: 15 }} disabled={[2, 4].includes(bundleObj.ref_copper_reg_status)} onClick={triggerFileInput}>
                                                        <MdOutlineModeEditOutline size={22} />
                                                    </FormButton>
                                                </FormButton.Group>
                                                :
                                                <FormButton style={{borderRadius: 15, width: 120}} onClick={() => {
                                                    showPhoto(e.file_name, e.bundle_no)
                                                    setBundleData({no: e.bundle_no, count: e.bundled_copper_count, weight: e.bundled_copper_weight})
                                                }}><PiEye size={22} /></FormButton>
                                            )
                                                :
                                                (<Upload accept="image/png, image/jpeg" onRemove={() => handleRemove(e.bundle_no)} fileList={files[e.bundle_no] ? [files[e.bundle_no]] : []} beforeUpload={(file) => beforeUpload(file, e.bundle_no)}>
                                                    <FormButton disabled={[2, 4].includes(bundleObj.ref_copper_reg_status)} style={{ fontSize: 13, width: '120px', borderRadius: 15 }} icon={type === 1 && <MdOutlinePhotoSizeSelectActual size={20}/>}>{type === 1 ? "хавсаргах" : 'Зураг оруулаагүй'}</FormButton>
                                                </Upload>)
                                            }
                                            <Button visible={i !== 0 && (e.lot_no && bundleObj.current_lot_no === e.lot_no) && ![2, 4].includes(bundleObj.ref_copper_reg_status)} text='-' className='minus' type='normal' onClick={() => removeBundle(e.bundle_no)} />
                                        </div>
                                    ))}
                            </div>
                            <div style={{ display: type === 1 ? 'flex' : 'none' }}>
                                <div className='card d-flex justify-space-between p-2 align-item-center flex-col' style={{ width: 100, height: 150, marginTop: 0 }}>
                                    <p style={{ textAlign: 'center' }}>Боолт</p>
                                    <p className='balance-text' style={{ marginTop: 15 }}>{bundleObj?.bundleData && bundleObj.bundleData.length === 1 ? '-' : `${bundleObj?.bundleData?.length} ш`}</p>
                                    <p className='balance-text'>{bundleObj?.bundleData && bundleObj?.bundleData.length === 1 ? '-' : `${_.sumBy(bundleObj?.bundleData, 'bundled_copper_weight')} кг`}</p>
                                </div>
                                <div className='card d-flex justify-space-between p-2 align-item-center flex-col' style={{ width: 100, height: 150, marginTop: 0 }}>
                                    <p style={{ textAlign: 'center' }}>Эцсийн үлдэгдэл</p>
                                    <p className='balance-text'>{sumObj.balance_copper_count ? `${sumObj.balance_copper_count} ш` : '-'}</p>
                                    <p className='balance-text'>{sumObj.balance_copper_weight ? `${sumObj.balance_copper_weight} кг` : '-'}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingBottom: 22, position: 'absolute', right: 10, bottom: 0, display: type === 1 ? 'block' : 'none' }}>
                            <Button text={'Хадгалах'} type='normal' style={{ borderRadius: 20, fontWeight: 600, marginRight: 10 }} width={90} height={32}
                                disabled={[2, 4].includes(bundleObj.ref_copper_reg_status) || (!bundleObj.start_balance_copper_count && !bundleObj.start_balance_copper_weight)}
                                onClick={onSaveBundle} />
                        </div>
                    </div>
                </div>
            }
            <Popup
                visible={popup}
                onHiding={() => setPopup(false)}
                height={500}
                width={500}
            >
                <div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 10, marginBottom: 15}}>
                        {arr.map((e,i)=>{
                            let arr1 = [bundleData.no, bundleData.count, bundleData.weight]
                            return (
                                <div className='d-flex g-2'>
                                    <span style={{fontWeight: 'bold'}}>{e}</span>
                                    <span>{arr1[i]}</span>
                                </div>
                            )
                        })}
                    </div>
                <Image
                    preview={false}
                    src={fileRoute}
                    width={'100%'}
                    height={300}
                />
                </div>
            </Popup>
        </BunldeDiv>
    )
}

export default Bundle