import React, { useEffect, useState } from 'react'
import CopperService from '../../services/api/copper';
import _ from 'lodash';
import moment from 'moment';
import { Button, ConfigProvider, DatePicker, Select, Tooltip } from 'antd';
import { MdOutlineArrowDropDown } from "react-icons/md";
import { RiCalendar2Line } from "react-icons/ri";
import { DateBox, DateRangeBox, LoadPanel, Popup } from 'devextreme-react';
import { FiMoon, FiSun } from 'react-icons/fi';
import EmptyData from '../../components/EmptyData';
import { CopperRegistration } from '../copper/CopperRegistration'
import { CgDetailsMore } from "react-icons/cg";




const CopperRegistrationReport = () => {
    const [loader, setLoader] = useState(false)
    const [eelj1Data, setEelj1Data] = useState([])
    const [eelj2Data, setEelj2Data] = useState([])
    const [sumObj, setSumObj] = useState({
        copper_count: 0,
        copper_weigth: 0,
        rejected_copper_count: 0,
        rejected_copper_weigth: 0,
    })
    const [selectedType, setSelectedType] = useState(1)
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [showDetail, setShowDetail] = useState(false)
    const [detail, setDetail] = useState({})

    useEffect(() => {
        if (selectedType === 1) {
            getBothShiftData()
        }
    }, [date])
    const getBothShiftData = async () => {
        try {
            setLoader(true)
            let prevDay = getPreviousWorkday(date)
            let current = await CopperService.getCopperRegDetail(date, 2);
            let current1 = await CopperService.getCopperRegDetail(date, 1);
            let prev = await CopperService.getCopperRegDetail(prevDay, 2);
            current1.data && setEelj1Data(current1.data[0])
            current.data && setEelj2Data(current.data[0])

            const copper_count = _.sumBy(current.data[0].bathData, 'copper_count') + _.sumBy(current1.data[0].bathData, 'copper_count'); //Тухайн өдрийн хураалт 1,2 ш
            const copper_weight = _.sumBy(current.data[0].bathData, 'total_weight') + _.sumBy(current1.data[0].bathData, 'total_weight'); // Тухайн өдрийн хураалт 1,2 кг 
            const rejected_copper_count = _.sumBy(current.data[0].bathData, 'rejected_copper_count') + _.sumBy(current1.data[0].bathData, 'rejected_copper_count'); // Тухайн өдрийн гологдол 1,2 ш
            const rejected_copper_weight = _.sumBy(current.data[0].bathData, 'rejected_copper_weigth') + _.sumBy(current1.data[0].bathData, 'rejected_copper_weigth');// Тухайн өдрийн гологдол 1,2 кг
            const total_copper_count = copper_count + (current1.data[0].start_balance_copper_count || 0)
            const total_copper_weight = copper_weight + (current1.data[0].start_balance_copper_weight || 0)
            const balance_copper_count = total_copper_count - (_.sumBy(current.data[0].bundleData, 'bundled_copper_count') + _.sumBy(current1.data[0].bundleData, 'bundled_copper_count'))
            const balance_copper_weight = total_copper_weight - (_.sumBy(current.data[0].bundleData, 'bundled_copper_weight') + _.sumBy(current1.data[0].bundleData, 'bundled_copper_weight'))
            setSumObj({
                balance_copper_count: prev.data[0].balance_copper_count || 0,
                balance_copper_weight: prev.data[0].balance_copper_weight || 0,
                copper_count: copper_count,
                copper_weight: copper_weight,
                rejected_copper_count: rejected_copper_count,
                rejected_copper_weight: rejected_copper_weight,
                total_copper_count: total_copper_count,
                total_copper_weight: total_copper_weight,
                bundle_count: current.data[0].bundleData.length + current1.data[0].bundleData.length,
                bundle_weight: _.sumBy(current.data[0].bundleData, 'bundled_copper_weight') + _.sumBy(current1.data[0].bundleData, 'bundled_copper_weight'),
                today_balance: balance_copper_count,
                today_balance_weight: balance_copper_weight,

            })
            return setLoader(false)
        } catch (e) {
            setLoader(false)
            console.error('err');
        }
    }
    function getPreviousWorkday(dateStr) {
        let date = new Date(dateStr)
        let day = date.getDay();
        if (day === 1) {
            date.setDate(date.getDate() - 3);
        }
        else {
            date.setDate(date.getDate() - 1);
        }
        return date.toLocaleDateString('en-CA');
    }

    const selectItems = [
        { value: 1, label: 'Өдрөөр' },
        { value: 2, label: '7 хоногоор' },
        { value: 3, label: 'Сараар' },
        { value: 4, label: 'Жилээр' }
    ]
    const handleDate = (e) => {
        setDate(e)
    }
    const disableWeekends = (args) => {
        const dayOfWeek = args.date.getDay();
        const isWeekend = args.view === "month" && (dayOfWeek === 0 || dayOfWeek === 6);
        return isWeekend
    }
    const [selectedWeek, setSelectedWeek] = useState({ start: null, end: null });
    const handleDateChange = (date) => {
        setSelectedWeek({
            start: moment(date[0]).format('YYYY-MM-DD'),
            end: moment(date[1]).format('YYYY-MM-DD')
        });
    };

    const isWeekend = (date) => {
        const dayOfWeek = date.isoWeekday(); // 1 = Monday, 7 = Sunday
        return dayOfWeek === 6 || dayOfWeek === 7; // Saturday or Sunday
    };
    const onInitialized = (e) => {
        console.log(e);
    }
    const handleClick = (e) => {
        setDetail({ shift_id: e, shift_date: date })
        setShowDetail(true)
    }
    return (
        <div>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loader}
                showPane={false}
                message="Түр хүлээнэ үү..."
            />
            <ConfigProvider
                theme={{
                    components: {
                        Select: {
                            controlHeight: 40
                        }
                    },
                    token: {
                        borderRadius: 20,
                        height: 40,
                        colorBorder: '#FFF'
                    }
                }}
            >
                <div className='m-2 d-flex g-2 align-items-center customizeDateBox'>

                    <Select
                        style={{ width: 130, textAlign: 'center' }}
                        options={selectItems}
                        value={selectedType}
                        disabled
                        onChange={(e) => setSelectedType(e)}
                    // suffixIcon={<MdOutlineArrowDropDown/>}
                    />
                    {
                        selectedType === 2 ?
                            <DateRangeBox
                                labelMode='hidden'
                                onInitialized={onInitialized}
                                width={300}
                                style={{ borderRadius: 20, border: 'none' }}
                                displayFormat='yyyy-MM-dd'
                                //  disableOutOfRangeSelection={true}
                                onValueChange={(e) =>
                                    handleDateChange(e)

                                }
                            />
                            :
                            <DateBox
                                width={160}
                                height={40}
                                value={date}
                                // defaultValue={date}
                                type='date'
                                stylingMode='outlined'
                                disabledDates={disableWeekends}
                                displayFormat={selectedType === 4 ? 'yyyy' : selectedType === 3 ? 'yyyy-MM' : 'yyyy-MM-dd'}
                                onValueChanged={(e) => {
                                    handleDate(e.value)
                                }}
                                calendarOptions={
                                    selectedType === 4 ?
                                        {
                                            zoomLevel: "decade",
                                            maxZoomLevel: 'decade',
                                            minZoomLevel: 'decade'
                                        }
                                        :
                                        selectedType === 3 ?
                                            {
                                                zoomLevel: 'year',
                                                maxZoomLevel: 'year',
                                                minZoomLevel: 'year'
                                            } :
                                            {
                                                zoomLevel: 'month',
                                                maxZoomLevel: 'month',
                                                minZoomLevel: 'month'
                                            }

                                }

                                style={{ borderRadius: 20, fontSize: 14, border: 'none', paddingLeft: 15 }}
                            />
                    }
                </div>
                {selectedType === 1 ?
                    <div>
                        <div className='card' style={{ padding: '10px 20px', border: 'none' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                                <h5 style={{ textTransform: 'uppercase', fontSize: 13, fontWeight: 700, letterSpacing: 0.2 }}>Зэс хураалтын ерөнхий мэдээ</h5>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'start', gap: 20, flexWrap: 'wrap' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 5, width: '100%', maxWidth: '320px' }}>
                                    <div className='row' style={{ alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <span style={{ width: 'calc(100%/2 - 5px)' }}>1-р ээлжийн мастер:</span>
                                        <div style={{ alignItems: 'center', justifyContent: 'space-between', height: 40 }}>
                                            <div className='eelj1' style={{ flexWrap: 'wrap', border: '1px solid #ced4df', width: 160 }}>
                                                <div style={{ borderRight: '1px solid #DEDEDE', width: '75%', display: 'flex', alignItems: 'center' }}>
                                                    <p style={{ padding: '7px 0 8px 15px' }}>{eelj1Data?.createdUser?.first_name ? `${eelj1Data?.createdUser?.last_name.slice(0)}.${eelj1Data?.createdUser?.first_name}` : '-'}</p>
                                                </div>
                                                <div style={{ width: '25%', textAlign: 'center', margin: 'auto', verticalAlign: 'middle', paddingTop: 3 }}>
                                                    <FiSun size={17} color='orange' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row' style={{ alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <span style={{ width: 'calc(100%/2 - 5px)' }}>2-р ээлжийн мастер:</span>
                                        <div style={{ alignItems: 'center', justifyContent: 'space-between', height: 40 }}>
                                            <div className='eelj1' style={{ flexWrap: 'wrap', border: '1px solid #ced4df', width: 160 }}>
                                                <div style={{ borderRight: '1px solid #DEDEDE', width: '75%', display: 'flex', alignItems: 'center' }}>
                                                    <p style={{ padding: '7px 0 8px 15px' }}>{eelj2Data?.createdUser?.first_name ? `${eelj2Data?.createdUser?.last_name.slice(0)}.${eelj2Data?.createdUser?.first_name}` : '-'}</p>
                                                </div>
                                                <div style={{ width: '25%', textAlign: 'center', margin: 'auto', verticalAlign: 'middle', paddingTop: 3 }}>
                                                    <FiMoon size={17} color='#003696' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row' style={{ alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <span style={{ width: 'calc(100%/2 - 5px)' }}>Төлөвлөгч:</span>
                                        <span style={{ borderRadius: 20, border: '1px solid #ced4df', height: 35, padding: '8px 8px 8px 20px', width: 160 }}> {'-'}</span>
                                    </div>
                                    <div className='row' style={{ alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <span style={{ width: 'calc(100%/2 - 5px)' }}>Ээлжийн ахлах:</span>
                                        <span style={{ borderRadius: 20, border: '1px solid #ced4df', height: 35, padding: '8px 8px 8px 20px', width: 160 }}>{'-'}</span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'baseline', gap: 10, flexWrap: 'wrap', width: 'calc(70% - 10px)', maxWidth: 'calc(70% - 10px)' }}>
                                    <div style={{ ...cardStyle }}>
                                        <p style={{ ...height }}>Өмнөх үлдэгдэл</p>
                                        <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{sumObj.balance_copper_count ? `${sumObj.balance_copper_count} ш` : '-'}</p>
                                        <p style={bottom}>{sumObj.balance_copper_weight ? `${sumObj.balance_copper_weight} кг` : '-'}</p>
                                    </div>
                                    <div style={cardStyle}>
                                        <p style={{ ...height }}>Хоногийн хураалт</p>
                                        <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{sumObj.copper_count ? `${sumObj.copper_count} ш` : '-'}</p>
                                        <p style={bottom}>{sumObj.copper_weight ? `${sumObj.copper_weight} кг` : '-'}</p>
                                    </div>
                                    <div style={cardStyle}>
                                        <p style={{ ...height }}>Гологдол</p>
                                        <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{sumObj.rejected_copper_count ? `${sumObj.rejected_copper_count} ш` : '-'}</p>
                                        <p style={bottom}>{sumObj.rejected_copper_weight ? `${sumObj.rejected_copper_weight} кг` : '-'}</p>
                                    </div>
                                    <div style={{ ...cardStyle }}>
                                        <p style={{ ...height }}>Нийт</p>
                                        <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{sumObj.total_copper_count ? `${sumObj.total_copper_count} ш` : '-'}</p>
                                        <p style={bottom}>{sumObj.total_copper_weight ? `${sumObj.total_copper_weight} кг` : '-'}</p>
                                    </div>
                                    <div style={{ ...cardStyle }}>
                                        <p style={{ ...height }}>Боолт</p>
                                        <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{sumObj.bundle_count ? `${sumObj.bundle_count} ш` : '-'}</p>
                                        <p style={bottom}>{sumObj.bundle_weight ? `${sumObj.bundle_weight} кг` : '-'}</p>
                                    </div>
                                    <div style={cardStyle}>
                                        <p style={{ ...height }}>Эцсийн үлдэгдэл</p>
                                        <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{sumObj.today_balance ? `${sumObj.today_balance} ш` : '-'}</p>
                                        <p style={bottom}>{sumObj.today_balance_weight ? `${sumObj.today_balance_weight} кг` : '-'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='card col-6' style={{ padding: '10px 20px', border: 'none' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                                    <h5 style={{ textTransform: 'uppercase', fontSize: 13, fontWeight: 700, letterSpacing: 0.2 }}>1-р ээлжийн зэс хураалтын мэдээ</h5>
                                    <Tooltip title='Дэлгэрэнгүй' placement='bottom'>

                                        <Button icon={<CgDetailsMore />} onClick={() => {
                                            handleClick(1)
                                        }} />
                                    </Tooltip>
                                </div>
                                {
                                    eelj1Data ?
                                        <div className='d-flex g-2 align-item-center'>
                                            <div style={{ ...cardStyle }}>
                                                <p style={{ ...height }}>Хураалт</p>
                                                <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{eelj1Data ? `${_.sumBy(eelj1Data.bathData, 'copper_count')} ш` : '-'}</p>
                                                <p style={bottom}>{eelj1Data ? `${_.sumBy(eelj1Data.bathData, 'total_weight')} кг` : '-'}</p>
                                            </div>
                                            <div style={{ ...cardStyle }}>
                                                <p style={{ ...height }}>Гологдол</p>
                                                <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{eelj1Data ? `${_.sumBy(eelj1Data.bathData, 'rejected_copper_count')} ш` : '-'}</p>
                                                <p style={bottom}>{eelj1Data ? `${_.sumBy(eelj1Data.bathData, 'rejected_copper_weigth')} кг` : '-'}</p>
                                            </div>
                                            <div style={{ ...cardStyle }}>
                                                <p style={{ ...height }}>Боолт</p>
                                                <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{eelj1Data && eelj1Data.bundleData ? `${eelj1Data.bundleData.length} ш` : '-'}</p>
                                                <p style={bottom}>{eelj1Data && eelj1Data.bundleData ? `${_.sumBy(eelj1Data.bundleData, 'bundled_copper_weight')} кг` : '-'}</p>
                                            </div>
                                        </div>
                                        :
                                        <EmptyData />
                                }
                            </div>
                            <div className='card col-6' style={{ padding: '10px 20px', border: 'none' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                                    <h5 style={{ textTransform: 'uppercase', fontSize: 13, fontWeight: 700, letterSpacing: 0.2 }}>2-р ээлжийн зэс хураалтын мэдээ</h5>
                                    <Tooltip title='Дэлгэрэнгүй' placement='bottom'>
                                        <Button icon={<CgDetailsMore />} onClick={() => {
                                            handleClick(2)
                                        }} />

                                    </Tooltip>
                                </div>
                                {
                                    eelj2Data ?
                                        <div className='d-flex g-2 align-item-center'>
                                            <div style={{ ...cardStyle }}>
                                                <p style={{ ...height }}>Хураалт</p>
                                                <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{eelj2Data ? `${_.sumBy(eelj2Data.bathData, 'copper_count')} ш` : '-'}</p>
                                                <p style={bottom}>{eelj2Data ? `${_.sumBy(eelj2Data.bathData, 'total_weight')} кг` : '-'}</p>
                                            </div>
                                            <div style={{ ...cardStyle }}>
                                                <p style={{ ...height }}>Гологдол</p>
                                                <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{eelj2Data ? `${_.sumBy(eelj2Data.bathData, 'rejected_copper_count')} ш` : '-'}</p>
                                                <p style={bottom}>{eelj2Data ? `${_.sumBy(eelj2Data.bathData, 'rejected_copper_weigth')} кг` : '-'}</p>
                                            </div>
                                            <div style={{ ...cardStyle }}>
                                                <p style={{ ...height }}>Боолт</p>
                                                <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{eelj2Data && eelj2Data.bundleData ? `${eelj2Data.bundleData.length} ш` : '-'}</p>
                                                <p style={bottom}>{eelj2Data && eelj2Data.bundleData ? `${_.sumBy(eelj2Data.bundleData, 'bundled_copper_weight')} кг` : '-'}</p>
                                            </div>
                                        </div>
                                        :
                                        <EmptyData />
                                }
                            </div>
                        </div>
                        {showDetail &&
                            <Popup
                                visible={showDetail}
                                title={detail.shift_date}
                                onHiding={() => setShowDetail(false)}
                                showCloseButton
                                width={'80%'}
                                height={'80%'}
                            >
                                <CopperRegistration shift_id={detail.shift_id} shift_date={detail.shift_date} />

                            </Popup>
                        }
                    </div>
                    :
                    <EmptyData />
                }
            </ConfigProvider>
        </div>
    )
}
const height = {
    height: 'calc(100%/3 - 5px)'
}
const bottom = {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    height: 'calc(100%/3 - 5px)'
}
const cardStyle = {
    maxWidth: 130,
    height: 150,
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: '20px 20px 10px 20px',
    border: '1px solid #ced4df',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexGrow: 1
}
export default CopperRegistrationReport