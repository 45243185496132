import { DateBox, Popup, Button, LoadPanel } from 'devextreme-react'
import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { FiSun, FiMoon, FiClock } from "react-icons/fi";
import { ConfigProvider, Input } from 'antd';
import _ from 'lodash';
import CopperService from '../../services/api/copper';
import RefService from '../../services/api/ref';
import styled from 'styled-components';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { UserContext } from '../../hooks/UserContext';
import Bundle from './Bundle';
import CopperHistory from './CopperHistory';
const ZesDiv = styled.div`
    border: none;
    padding: 10px;
    margin: 10px 5px;
 
    .textStart {
        vertical-align: top;
        text-align: start;
        padding: 10px;
        width: 200px !important;
        font-weight: 400;
    }
    table {
        table-layout: fixed;
        margin-bottom: 10px;
    }
    table, th, td {
        border: 1px solid #DEDEDE;
        border-collapse: collapse;
    }
    thead{
        background-color: #F4F5F7;
    }
    td {
        padding: 10px;
        text-align: center;
        width: 90px !important
    }
    thead tr:nth-child(0){
        text-align: start;
    }
    .unprepared {
        color: #ced4df
    }
    .underRepair {
        color: #f95f53
    }
    .ant-input-textarea::-webkit-scrollbar {
        width: 10px !important;
    }
    .ant-input-textarea::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 15px !important;
    }
  
    .ant-input-textarea::-webkit-scrollbar-thumb {
        background: #dedede;
        border-radius: 10px;
    }
    .ant-input-textarea::-webkit-scrollbar-thumb:hover {
        background: #333;
    }
`

const Registration = () => {
    const { user } = useContext(UserContext)
    const [date, setDate] = useState(moment().format('YYYY-MM-DD hh:mm'))
    const [bathArray, setBathArray] = useState([])
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true);
    const [sumObj, setSumObj] = useState({
        copper_count: 0,
        copper_weigth: 0,
        rejected_copper_count: 0,
        rejected_copper_weigth: 0,
    })
    const [isNight, setIsNight] = useState(false)
    const [visible, setVisible] = useState(false)
    const [reason, setReason] = useState('')
    const [rejectedType, setRejectedType] = useState([])
    const [sentDate, setSentDate] = useState(null)
    const [initial, setInital] = useState({
        count: 0,
        weight: 0
    })

    // ~~~~~~~~~~~~~~ Ахлах баталгаажуулах цонх ажиллах үед ~~~~~~~~~~~~~~ //
    useEffect(() => {
        getCopperRegByDate();
        getSentDate()
    }, [date, isNight])

    useEffect(() => {
        rejectedTypes()
    }, [])
    useEffect(() => {
        setInital({
            count: data?.start_balance_copper_count + sumObj?.copper_count,
            weight: data?.start_balance_copper_weight + sumObj?.total_weight
        })
    }, [data, sumObj])
    const getSentDate = async () => {
        try {
            moment.locale('mn')
            const res = await CopperService.getSentDate(date, isNight ? 2 : 1, 1)
            if (res.data.length === 0) {
                setSentDate(null)
            } else {
                let hours = moment(res.data[0].createdAt).format('HH:mm')
                setSentDate(hours)
            }
        } catch (err) {
            // console.log('err');
        }
    }
    const getCopperRegByDate = async () => {
        try {
            setLoader(true)
            let { data } = await CopperService.getCopperRegDetail(date, isNight ? 2 : 1);
            data && setBathArray(data[0].bathData);
            const { bathData, ...filtered } = data[0];
            setData({ ...filtered })
            const copper_count = _.sumBy(bathData, 'copper_count');
            const copper_weight = _.sumBy(bathData, 'total_weight');
            const rejected_copper_count = _.sumBy(bathData, 'rejected_copper_count');
            const rejected_copper_weight = _.sumBy(bathData, 'rejected_copper_weigth');
            const total_copper_count = copper_count + (data[0].start_balance_copper_count || 0)
            const total_copper_weight = copper_weight + (data[0].start_balance_copper_weight || 0)
            const balance_copper_count = total_copper_count - _.sumBy(data[0].bundleData, 'bundled_copper_count')
            const balance_copper_weight = total_copper_weight - _.sumBy(data[0].bundleData, 'bundled_copper_weight')
            setSumObj({
                balance_copper_count: data[0].start_balance_copper_count || 0,
                balance_copper_weight: data[0].start_balance_copper_weight || 0,
                copper_count: copper_count,
                copper_weight: copper_weight,
                rejected_copper_count: rejected_copper_count,
                rejected_copper_weight: rejected_copper_weight,
                total_copper_count: total_copper_count,
                total_copper_weight: total_copper_weight,
                bundle_count: data[0].bundleData.length,
                bundle_weight: _.sumBy(data[0].bundleData, 'bundled_copper_weight'),
                today_balance: balance_copper_count,
                today_balance_weight: balance_copper_weight,

            })
            return setLoader(false)
        } catch (e) {
            setLoader(false)
            console.error('err');
        }
    }
    const rejectRequest = async (e) => {
        try {
            setLoader(true)
            if (data && data.bundleData && data.bundleData.length === 0) {
                setLoader(false)
                setVisible(false)
                notify({
                    message: 'Боолт хийгээгүй байна',
                    width: 'fit-content',
                    position: { at: 'top center', my: 'top center', of: '.customizeDateBox' }
                },
                    "warning", 2000);

                return false
            }
            const res = await CopperService.changeRequestStatus({
                id: data.id,
                type: e,
                shift_id: data.shift_id,
                shift_date: data.shift_date,
                rejected_reason: e === 3 ? reason : ''
            })
            setVisible(false)
            if (res.code === 200) {
                await getCopperRegByDate()
                notify({
                    message: e === 3 ? 'Бүртгэл цуцлагдлаа' : 'Амжилттай баталлаа',
                    width: 'fit-content',
                    position: { at: 'top center', my: 'top center', of: '.customizeDateBox' }
                },
                    "success", 2000);
            }
            setLoader(false)
        } catch (err) {
            setVisible(false)
            notify({
                message: e === 3 ? 'Хүсэлтийг цулахад алдаа гарлаа' : 'Хүсэлтийг батлахад алдаа гарлаа',
                width: 'fit-content',
                position: { at: 'top center', my: 'top center', of: '.customizeDateBox' }
            },
                "error", 2000);
            setLoader(false)
        }
    }
    const rejectedTypes = async () => {
        try {
            let res = await RefService.getRejectedTypes()
            setRejectedType(res.data)
        } catch (e) {
            console.error(e);
        }
    }

    const disableWeekends = (args) => {
        const dayOfWeek = args.date.getDay();
        const isWeekend = args.view === "month" && (dayOfWeek === 0 || dayOfWeek === 6);
        return isWeekend
    }

    const requestHandler = async () => {
        let myDialog = custom({
            title: "Баталгаажуулалт",
            messageHtml: `<i>Та <b>зэс хураалтын мэдээлэл</b> баталгаажуулахдаа итгэлтэй байна уу?</i>`,
            buttons: [{
                text: "Тийм",
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                },
            },
            {
                text: "Үгүй",
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                },
            }]
        })
        myDialog.show().then(async (dialogResult) => {
            if (dialogResult.buttonText === "Тийм") {
                try {
                    setLoader(true)
                    if (data && data.bundleData && data.bundleData.length === 0) {
                        setLoader(false)
                        notify({
                            message: 'Боолт хийгээгүй байна',
                            width: 'fit-content',
                            position: { at: 'top center', my: 'top center', of: '.customizeDateBox' }
                        },
                            "warning", 2000);
                        return false
                    }
                    const res = await CopperService.changeRequestStatus({
                        id: data.id,
                        type: 4,
                        shift_id: data.shift_id,
                        shift_date: data.shift_date
                    })
                    const bundle = await CopperService.changeCurrentBundle({
                        bundle_no: data.bundleData[data.bundleData.length - 1].bundle_no + 1,
                        lot_no: data.lot_no,
                        shift_id: isNight ? 2 : 1,
                        shift_date: data.shift_date,
                        balance_copper_count: sumObj.today_balance,
                        balance_copper_weight: sumObj.today_balance_weight
                    })
                    if (res.code === 200 && bundle.code === 200) {
                        await getCopperRegByDate();
                        notify({ message: 'Амжилттай баталлаа', width: 'fit-content', position: { at: 'top center', my: 'top center', of: '.customizeDateBox' } }, "success", 3000);
                    }
                    setLoader(false)
                } catch (error) {
                    setLoader(false)
                    notify({
                        message: 'Хүсэлтийг батлахад алдаа гарлаа',
                        width: 'fit-content',
                        position: { at: 'top center', my: 'top center', of: '.customizeDateBox' }
                    },
                        "error", 3000);
                }
            }
        })

    }
    const bathStatus = [
        { id: 1, name: 'Бэлэн' },
        { id: 2, name: 'Бэлэн бус' },
        { id: 3, name: 'Засварт' },
    ]
    const statusColor = (id) => {
        switch (id) {
            case 1:
                return '#000';
            case 2:
                return '#ced4df';
            case 3:
                return '#f95f53';
            default:
                return '#ffffff';
        }
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~ END ~~~~~~~~~~~~~~~~~~~~~~ //
    return (
        <div id='registration'
            style={{
                border: 'none',
                color: 'black',
                cursor: 'default',
            }}>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loader}
                showPane={false}
                message="Түр хүлээнэ үү..."
            />
            <div className='customizeDateBox m-2 d-flex justify-space-between align-items-center' style={{ flexWrap: 'wrap', gap: 10 }}>
                <div className='d-flex g-2' >
                    <DateBox
                        width={160}
                        height={40}
                        defaultValue={date}
                        type='date'
                        stylingMode='outlined'
                        displayFormat='yyyy-MM-dd'
                        onValueChange={(e) => setDate(e)}
                        disabledDates={disableWeekends}
                        style={{ borderRadius: 20, fontSize: 14, border: 'none', paddingLeft: 15 }}
                    />
                    <div style={{ alignItems: 'center', justifyContent: 'space-between', height: 40 }}>
                        <div className='eelj' onClick={() => setIsNight(!isNight)} style={{ flexWrap: 'wrap', height: 40 }}>
                            <div style={{ borderRight: '1px solid #DEDEDE', width: '75%', display: 'flex', alignItems: 'center' }}>
                                <p style={{ padding: '7px 0 8px 15px', fontWeight: 'bold' }}>{isNight ? '2-р ээлж' : '1-р ээлж'}</p>
                            </div>
                            <div style={{ width: '25%', textAlign: 'center', margin: 'auto', verticalAlign: 'middle', paddingTop: 3 }}>
                                {!isNight ? <FiSun size={17} color='orange' /> : <FiMoon size={17} color='#003696' />}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', height: 40, width: 300 }}>
                        <div className='eelj1' style={{ width: 'calc(100%/2 - 5px)', backgroundColor: '#white', display: 'flex', borderRadius: '20px', height: '100%' }}>
                            <div style={{ borderRight: '1px solid #ced4df', width: '55%' }}>
                                <p style={{ padding: '9px 0 9px 20px', fontWeight: 600, }}>LOT-{data.lot_no}</p>
                            </div>
                            <div style={{ width: '45%' }}>
                                <p style={{ padding: '9px 0 9px 20px', fontWeight: 600, }}>{data.percent_lot ? `${data.percent_lot}%` : '-'}</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div style={{ fontSize: 14, height: 40 }}>
                    {
                        data && ![3, 4].includes(data.ref_copper_reg_status) ?
                            <div>
                                <button className='clickBtn' disabled={data.ref_copper_reg_status !== 2 || data?.bundleData?.length === 0 || user?.roles[0]?.role_id !== 6 ? true : false} onClick={() => requestHandler()} style={{ padding: '11px 20px', borderTopLeftRadius: 20, borderBottomLeftRadius: 20, fontSize: 14 }}>Батлах</button>
                                <button className='clickBtn' disabled={data.ref_copper_reg_status !== 2 || data?.bundleData?.length === 0 || user?.roles[0]?.role_id !== 6 ? true : false} onClick={() => setVisible(true)} style={{ padding: '10px 20px', borderLeft: '1px solid #DEDEDE', borderTopRightRadius: 20, borderBottomRightRadius: 20, fontSize: 14 }}>Цуцлах</button>
                            </div>
                            : data.ref_copper_reg_status === 3 ?
                                <button style={{ fontSize: 14, color: '#F95F53', backgroundColor: '#FFEFED', borderRadius: 20, border: '.5px solid #F95F53', fontWeight: 'bold', padding: '8px 20px', height: 40 }}>Татгалзсан</button>
                                :
                                data.ref_copper_reg_status === 4 ?
                                    <button style={{ fontSize: 14, color: '#40B6AF', backgroundColor: '#E5FEF7', borderRadius: 20, border: '.5px solid #40B6AF', fontWeight: 'bold', padding: '8px 20px', height: 40 }}>Баталсан</button>
                                    : ''
                    }
                </div>
            </div>
            <div className='card' style={{ padding: '10px 20px', border: 'none' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                    <h5 style={{ textTransform: 'uppercase', fontSize: 13, fontWeight: 700, letterSpacing: 0.2 }}>1. Зэс хураалтын ерөнхий мэдээ</h5>
                </div>
                <div style={{ display: 'flex', alignItems: 'start', gap: 20, flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 5, width: '100%', maxWidth: '300px' }}>
                        <div className='row' style={{ alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            <span style={{ width: 'calc(100%/2 - 5px)' }}>Ээлжийн мастер:</span>
                            <span style={{ borderRadius: 20, border: '1px solid #ced4df', height: 35, padding: '8px 8px 8px 20px', width: 'calc(100%/2 - 5px)' }}>{data?.createdUser?.first_name ? `${data?.createdUser?.last_name.slice(0)}.${data?.createdUser?.first_name}` : '-'}</span>
                        </div>
                        <div className='row' style={{ alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', height: 35 }}>
                            <span style={{ width: 'calc(100%/2 - 5px)' }}>Илгээсэн цаг:</span>
                            <div className='eelj1' style={{ width: 'calc(100%/2 - 5px)', border: '1px solid #ced4df', backgroundColor: '#white', display: 'flex', borderRadius: '20px' }}>
                                <div style={{ borderRight: '1px solid #ced4df', width: '75%' }}>
                                    <p style={{ padding: '7px 0 8px 20px', fontWeight: 600, }}>{sentDate ? sentDate : '-'}</p>
                                </div>
                                <div style={{ width: '25%', textAlign: 'center', margin: 'auto', verticalAlign: 'middle', paddingTop: 3 }}>
                                    <FiClock size={16} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'baseline', gap: 10, flexWrap: 'wrap', width: 'calc(70% - 10px)', maxWidth: 'calc(70% - 10px)' }}>
                        <div style={{ ...cardStyle }}>
                            <p style={{ ...height }}>Өмнөх үлдэгдэл</p>
                            <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{sumObj.balance_copper_count && data.ref_copper_reg_status !== 1 ? `${sumObj.balance_copper_count} ш` : '-'}</p>
                            <p style={bottom}>{sumObj.balance_copper_weight && data.ref_copper_reg_status !== 1 ? `${sumObj.balance_copper_weight} кг` : '-'}</p>
                        </div>
                        <div style={cardStyle}>
                            <p style={{ ...height }}>Хоногийн хураалт</p>
                            <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{sumObj.copper_count && data.ref_copper_reg_status !== 1 ? `${sumObj.copper_count} ш` : '-'}</p>
                            <p style={bottom}>{sumObj.copper_weight && data.ref_copper_reg_status !== 1 ? `${sumObj.copper_weight} кг` : '-'}</p>
                        </div>
                        <div style={cardStyle}>
                            <p style={{ ...height }}>Гологдол</p>
                            <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{sumObj.rejected_copper_count && data.ref_copper_reg_status !== 1 ? `${sumObj.rejected_copper_count} ш` : '-'}</p>
                            <p style={bottom}>{sumObj.rejected_copper_weight && data.ref_copper_reg_status !== 1 ? `${sumObj.rejected_copper_weight} кг` : '-'}</p>
                        </div>
                        <div style={{ ...cardStyle }}>
                            <p style={{ ...height }}>Нийт</p>
                            <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{sumObj.total_copper_count && data.ref_copper_reg_status !== 1 ? `${sumObj.total_copper_count} ш` : '-'}</p>
                            <p style={bottom}>{sumObj.total_copper_weight && data.ref_copper_reg_status !== 1 ? `${sumObj.total_copper_weight} кг` : '-'}</p>
                        </div>
                        <div style={{ ...cardStyle }}>
                            <p style={{ ...height }}>Боолт</p>
                            <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{sumObj.bundle_count && data.ref_copper_reg_status !== 1 ? `${sumObj.bundle_count} ш` : '-'}</p>
                            <p style={bottom}>{sumObj.bundle_weight && data.ref_copper_reg_status !== 1 ? `${sumObj.bundle_weight} кг` : '-'}</p>
                        </div>
                        <div style={cardStyle}>
                            <p style={{ ...height }}>Эцсийн үлдэгдэл</p>
                            <p style={{ fontWeight: 600, ...height, fontSize: 18, marginTop: 'auto' }}>{sumObj.today_balance && data.ref_copper_reg_status !== 1 ? `${sumObj.today_balance} ш` : '-'}</p>
                            <p style={bottom}>{sumObj.today_balance_weight && data.ref_copper_reg_status !== 1 ? `${sumObj.today_balance_weight} кг` : '-'}</p>
                        </div>
                    </div>
                </div>
            </div>
            <ZesDiv className='card'>
                <ConfigProvider
                    theme={{
                        token: {
                            borderRadius: 15,
                        }
                    }}
                >
                    <div className='d-flex justify-space-between align-item-center' style={{ marginBottom: '2rem', padding: '0 10px' }}>
                        <h4 style={{ fontSize: 13, fontWeight: 700, letterSpacing: 0.2 }}>2. ЗЭС ХУРААЛТЫН ДЭЛГЭРЭНГҮЙ</h4>
                        <div className="d-flex g-3 align-item-center">
                            Ванны төлөв: {bathStatus.map((e) => (
                                <div className='d-flex g-2 align-item-center'>
                                    <span className='badge' style={{ backgroundColor: statusColor(e.id) }}></span>
                                    {e.name}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='bundle-container' style={{ overflowX: 'auto' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className='textStart' nowrap='nowrap'>Ванны дугаар</th>
                                    {bathArray && bathArray?.map((item, i) => {
                                        return (
                                            <th style={{ padding: 5, borderLeft: '1px solid #ced4df' }} className={`${item.bath_status_id === 1 ? '' : item.bath_status_id === 2 ? 'unprepared' : 'underRepair'}`} key={i} colSpan={'5'}>№{item.bath_number}</th>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <td className='textStart' nowrap='nowrap'>Өлгүүрийн дугаар</td>
                                    {bathArray && bathArray?.map((e, i) => (
                                        <>
                                            {e.hangerData.map((item, index) => {
                                                return <td key={`${i}-${index}`} className={`custom-cell ${e.bath_status_id === 1 ? '' : e.bath_status_id === 2 ? 'unprepared' : 'underRepair'}`} onClick={() => console.log(e)
                                                }>{item.hanger_number}</td>
                                            })}
                                            <td key={`total-${i}`} className={`custom-cell ${e.bath_status_id === 1 ? '' : e.bath_status_id === 2 ? 'unprepared' : 'underRepair'}`} style={{ fontWeight: 'bold' }}>нийт</td>
                                            <td key={`rejected-${i}`} className={`custom-cell ${e.bath_status_id === 1 ? '' : e.bath_status_id === 2 ? 'unprepared' : 'underRepair'}`} style={{ fontWeight: 'bold' }} >гологдол</td>
                                        </>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='textStart' nowrap='nowrap'>Катодын тоо</td>
                                    {bathArray && bathArray?.map((e, i) => (
                                        <>
                                            {e.hangerData.map((item, index) => {
                                                return <td className={`custom-cell ${e.bath_status_id === 1 ? '' : e.bath_status_id === 2 ? 'unprepared' : 'underRepair'}`} key={`${i}-${index}`}>{item.katod_count && data.ref_copper_reg_status !== 1 ? item.katod_count : '-'}</td>
                                            })}
                                            <td className={`custom-cell ${e.bath_status_id === 1 ? '' : e.bath_status_id === 2 ? 'unprepared' : 'underRepair'}`} key={`total-${i}`}>{e.katod_count && data.ref_copper_reg_status !== 1 ? e.katod_count : '-'}</td>
                                            <td className={`custom-cell ${e.bath_status_id === 1 ? '' : e.bath_status_id === 2 ? 'unprepared' : 'underRepair'}`} key={`rejected-katod-${i}`}>-</td>
                                        </>
                                    ))}
                                </tr>
                                <tr>
                                    <td className='textStart' nowrap='nowrap'>Хавтангийн тоо</td>
                                    {bathArray && bathArray?.map((e, i) => (
                                        <>
                                            {e.hangerData.map((item, index) => {
                                                return <td className={`custom-cell ${e.bath_status_id === 1 ? '' : e.bath_status_id === 2 ? 'unprepared' : 'underRepair'}`} key={`${i}-${index}`}>{item.copper_count && data.ref_copper_reg_status !== 1 ? item.copper_count : '-'}</td>
                                            })}
                                            <td className={`custom-cell ${e.bath_status_id === 1 ? '' : e.bath_status_id === 2 ? 'unprepared' : 'underRepair'}`} key={`total-${i}`}>{e.copper_count && data.ref_copper_reg_status !== 1 ? e.copper_count : '-'}</td>
                                            <td className={`custom-cell ${e.bath_status_id === 1 ? '' : e.bath_status_id === 2 ? 'unprepared' : 'underRepair'}`} key={`rejected-katod-${i}`}>{e.rejected_copper_count && data.ref_copper_reg_status !== 1 ? e.rejected_copper_count : '-'}</td>
                                        </>
                                    ))}
                                </tr>
                                <tr>
                                    <td className='textStart' nowrap='nowrap'>Хавтангийн жин</td>
                                    {bathArray && bathArray?.map((e, i) => {
                                        return (
                                            <>
                                                {e.hangerData.map((item, index) => {
                                                    return <td className={`custom-cell ${e.bath_status_id === 1 ? '' : e.bath_status_id === 2 ? 'unprepared' : 'underRepair'}`} key={`${i}-${index}`}>{item.copper_weigth && data.ref_copper_reg_status !== 1 ? item.copper_weigth : '-'}</td>
                                                })}
                                                <td className={`custom-cell ${e.bath_status_id === 1 ? '' : e.bath_status_id === 2 ? 'unprepared' : 'underRepair'}`} key={`total-${i}`}>{e.total_weight && data.ref_copper_reg_status !== 1 ? e.total_weight : '-'}</td>
                                                <td className={`custom-cell ${e.bath_status_id === 1 ? '' : e.bath_status_id === 2 ? 'unprepared' : 'underRepair'}`} key={`rejected-katod-${i}`}>{e.rejected_copper_weigth && data.ref_copper_reg_status !== 1 ? e.rejected_copper_weigth : '-'}</td>
                                            </>
                                        )
                                    })}
                                </tr>
                                <tr style={{ height: 50 }}>
                                    <td className='textStart' nowrap='nowrap'>Хоосон катодын тайлбар</td>
                                    {bathArray && bathArray?.map((e, i) => {
                                        return <td style={{ paddingRight: 0 }} colSpan={5} key={i}>
                                            <Input.TextArea
                                                rows={5}
                                                value={e.description}
                                                style={{ borderRadius: 0, padding: 0, border: 'none', textAlign: 'justify', paddingRight: 10 }}
                                                autoSize={{
                                                    minRows: 3,
                                                    maxRows: 5,
                                                }}
                                            />
                                        </td>
                                    }
                                    )}
                                </tr>
                                <tr>
                                    <td className='textStart' nowrap='nowrap'>Гологдлын шалтгаан</td>
                                    {bathArray && bathArray?.map((e, i) => {
                                        const currentType = rejectedType.find(type => type.id === e.copper_rejected_type_id);
                                        return <td key={`${i}`} colSpan={'5'}>
                                            {currentType ? currentType.name : ''}
                                        </td>
                                    }
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ConfigProvider>
            </ZesDiv>
            <Bundle getCopperRegByDate={getCopperRegByDate} sumObj={sumObj} bundleObj={data} type={2} initial={initial} />
            <div className='card' style={{ gap: 5, padding: 5, border: 'none', marginTop: 10 }}>
                <CopperHistory shift_date={date} shift_id={isNight ? 2 : 1} />
            </div>
            <Popup
                visible={visible}
                onHiding={() => setVisible(false)}
                width={'25%'}
                height={300}
                title={'Хүсэлтийг татгалзах'}
                showCloseButton
                style={{ borderRadius: 15 }}
                dragEnabled={false}
                contentRender={() => {
                    return (
                        <div style={{ textAlign: 'end', display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'end', gap: 20 }}>
                            <Input.TextArea
                                value={reason}
                                onChange={(e) => {
                                    setReason(e.target.value)
                                }}
                                autoSize={{
                                    minRows: 7,
                                    maxRows: 7
                                }}
                                style={{ minHeight: 100, height: 150, maxHeight: 200, width: '100%', borderRadius: 15, padding: 10 }}
                                placeholder='Шалтгаан...'
                                maxLength={500}
                            />
                            <Button onClick={(e) => {
                                rejectRequest(3)
                            }} text='Илгээх' style={{ width: 100, backgroundColor: 'black', color: 'white', fontWeight: 600, borderRadius: 20 }} />
                        </div>
                    )
                }}
            >

            </Popup>
        </div>
    )
}
const height = {
    height: 'calc(100%/3 - 5px)'
}
const bottom = {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    height: 'calc(100%/3 - 5px)'
}
const cardStyle = {
    maxWidth: 130,
    height: 150,
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: '20px 20px 10px 20px',
    border: '1px solid #ced4df',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexGrow: 1
}

export default Registration