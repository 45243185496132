import React, {useContext, useEffect, useState} from 'react';
import {
    DataGrid,
    Column,
    Paging,
    Scrolling,
    ColumnFixing, Editing
} from "devextreme-react/data-grid";
import copperService from "../../services/api/copper";
import {LoadPanel} from "devextreme-react/load-panel";
import {onCellPreparedHandler, sortingText} from "../../util/index"
import {DateBox} from "devextreme-react";
import notify from "devextreme/ui/notify";
import {UserContext} from "../../hooks/UserContext";
const CopperProductPlan = () => {
    const {user} = useContext(UserContext);
    const [mainData, setMainData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    useEffect(() => {
        getList();
    }, [year,month]);
    const getList = async () => {
        try {
            setLoader(true);
            const result = await copperService.getCopperProductPlan(year,month);
            console.log('result', result);
            setMainData(result.data);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const cellTemplate = (cellData) => {
        switch (cellData.text) {
            case 'Jan':
                return '1-р сар'
            case 'Feb':
                return '2-р сар'
            case 'Mar':
                return '3-р сар'
            case 'Apr':
                return '4-р сар'
            case 'May':
                return '5-р сар'
            case 'Jun':
                return '6-р сар'
            case 'Jul':
                return '7-р сар'
            case 'Aug':
                return '8-р сар'
            case 'Sep':
                return '9-р сар'
            case 'Oct':
                return '10-р сар'
            case 'Nov':
                return '11-р сар'
            case 'Dec':
                return '12-р сар'
        }
    }
    const onValueChangedDate = (e) => {
        let value = new Date(e.value);
        setYear(value.getFullYear());
        setMonth(value.getMonth() + 1);
    }
    const setEffect = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            setLoader(true);
            console.log('e.changes', e.changes);
            try {
                e.changes[0].data.updated_user_id = user.id;
                await copperService.mergeCopperProductPlan(e.changes);
                await getList();
                setLoader(false);
               return notify('Амжилттай хадгалагдлаа', "success", 2000);
            } catch (e) {
                setLoader(false);
                notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
            }
        }
    }
    const textAreaOptions = {format: '#,##0'};

    const setCellValue = (newData, value, currentRowData) => {
        console.log("setCellValue newData: ", newData);
        console.log("setCellValue value: ", value);
        console.log("setCellValue currentRowData: ", currentRowData);
        newData.lot_no = value;
        //newData.worker_id = value;
        //if(value) newData.first_name = workerData.filter(e=>e.id === value)[0].first_name;


    }
    const onEditorPreparing = (e) => {
        console.log('onEditorPreparing', e);
        if (e.parentType === "dataRow" && e.dataField === "lot_no" && e.row.data.copper_day_plan === null) {
            e.allowEditing = false;
            e.readOnly = true;
            e.editorOptions.readOnly = true;
            e.editorOptions.disabled = true;
        }
    }
    return (
        <div>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#copperProductPlan'}}
                visible={loader}
                showPane={false}
                message="Түр хүлээнэ үү..."
            />

            <div style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: "flex-start",
                display:'flex',
            }}>
               <div>
                   <DateBox applyValueMode="instantly"
                            type='date'
                            width={120}
                            stylingMode='outlined'
                            defaultValue={new Date()}
                            placeholder={"Та зэс хураалтын төлөвлөгөө сараа заавал сонгоно уу"}
                            displayFormat="yyyy-MM"
                            applyButtonText="Сонгох"
                            cancelButtonText="Болих"
                            invalidDateMessage={'Формат буруу байна жишээ нь 2024-01'}
                            calendarOptions={{
                                maxZoomLevel: 'year',
                                minZoomLevel: 'century',
                                cellTemplate: cellTemplate
                            }}
                            style={{borderRadius: 20, fontSize: 14, border: 'none',paddingLeft: 10 }}
                            onValueChanged={onValueChangedDate}
                   />
               </div>
                <span style={{marginLeft:10,color:'red'}}>Under construction...</span>
            </div>
            <div>
                <DataGrid
                    dataSource={mainData}
                    //rowAlternationEnabled={true}
                    allowColumnResizing={true}
                    onCellPrepared={(e) => {
                        return onCellPreparedHandler(e);
                    }}
                    columnAutoWidth={true}
                    showBorders={true}
                    showRowLines={true}
                    showColumnLines={true}
                    showColumnHeaders={true} sorting={sortingText}
                    paging={{enabled: true, pageSize: 23,}}
                    noDataText="Дата байхгүй байна."
                    keyExpr={"plan_date"}
                    wordWrapEnabled={true}
                    onSaved={setEffect}
                    onEditorPreparing={onEditorPreparing}
                >
                    <Scrolling useNative={false} scrollByContent={true}/>
                    <ColumnFixing enabled={false}/>
                    <Column
                        cssClass="custom"
                        dataField="plan_date"
                        width={100}
                        dataType={"date"}
                        format={"yyyy-MM-dd"}
                        caption="Хугацаа"
                        allowEditing={false}
                        fixed={true}
                    >
                    </Column>
                    <Column
                        width={100}
                        cssClass="custom"
                        dataField="katod_count"
                        dataType={"number"}
                        caption="Катодын хэмжээ, ш"
                        allowEditing={false}
                        allowFiltering={false} format="#" alignment="center"
                    >
                    </Column>
                    <Column
                        width={100}
                        cssClass="custom"
                        dataField="katod_count_by_bath"
                        dataType={"number"}
                        caption="Нэгж ваннд байх катодын хэмжээ, ш"
                        allowEditing={false}
                        allowFiltering={false} format="#" alignment="center"
                    >
                    </Column>
                    <Column
                        width={100}
                        cssClass="custom"
                        dataField="amper_measure"
                        dataType={"number"}
                        caption="Гүйдлийн хэмжээ, А"
                        allowEditing={false}
                        allowFiltering={false} format="#" alignment="right"
                    >
                    </Column>
                    <Column
                        width={100}
                        cssClass="custom"
                        dataField="real_amper_measure"
                        dataType={"number"}
                        caption="Бодит гүйдлийн хэмжээ, А"
                        allowEditing={false}
                        allowFiltering={false} format="#" alignment="right"
                    >
                    </Column>
                    <Column caption={'Лот бүрдэлт төлөвлөгөө'} alignment={'center'} allowResizing={true}>
                        <Column
                            //width={100}
                            cssClass="custom"
                            dataField="lot_no"
                            dataType={"number"}
                            caption="LOT#"
                            allowEditing={true}
                            allowFiltering={false} format="#" alignment="center"
                            setCellValue={setCellValue}
                        >
                        </Column>
                        <Column
                            // width={130}
                            cssClass="custom"
                            dataField="copper_day_plan"
                            dataType={"number"}
                            caption="кг/хон Cu"
                            allowEditing={true}
                            allowFiltering={false} format="#,##0" alignment="right" editorOptions={textAreaOptions}
                        >
                        </Column>
                        <Column
                            //width={130}
                            cssClass="custom"
                            dataField="lot_weight"
                            dataType={"number"}
                            caption="Жин,кг"
                            allowEditing={false}
                            allowFiltering={false} format="#,##0" alignment="right"
                        >
                        </Column>
                    </Column>
                    <Column caption={'Лот бүрдэлт гүйцэтгэл'} alignment={'center'} allowResizing={true}>
                        <Column
                            //width={100}
                            cssClass="custom"
                            dataField="bundled_lot_no"
                            dataType={"number"}
                            caption="LOT#"
                            allowEditing={false}
                            allowFiltering={false} format="#" alignment="center"
                        >
                        </Column>
                        <Column
                            // width={130}
                            cssClass="custom"
                            dataField="performance_day_copper"
                            dataType={"number"}
                            caption="кг/хон"
                            allowEditing={false}
                            allowFiltering={false} format="#,##0" alignment="right"
                        >
                        </Column>
                        <Column
                            //width={130}
                            cssClass="custom"
                            dataField="performance_cum_amt"
                            dataType={"number"}
                            caption="Өссөн дүнгээр"
                            allowEditing={false}
                            allowFiltering={false} format="#,##0" alignment="right"
                        >
                        </Column>
                    </Column>
                    <Editing
                        mode="batch"
                        allowUpdating={true}
                        useIcons={true}
                        texts={{
                            cancelAllChanges: 'Болих бүгд',
                            cancelRowChanges: 'Болих',
                            confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                            confirmDeleteTitle: 'Баталгаажуулах',
                            deleteRow: 'Устгах',
                            editRow: 'Өөрчлөх',
                            saveAllChanges: 'Хадгалах бүгд',
                            saveRowChanges: 'Хадгалах',
                            undeleteRow: 'Буцаах',
                            validationCancelChanges: 'Баталгаажуулах болих',
                        }}
                    >
                    </Editing>
                    <Paging enabled={true} defaultPageSize={23}/>
                </DataGrid>
            </div>
        </div>
    )
};

export default CopperProductPlan;